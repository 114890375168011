<template>
  <div class="grid admintext-demo">
    <Toolbar class="toolbar_top">
      <template v-slot:start>
        <div class="my-2">
          <h3>{{ t('textManagemet') }}</h3>
        </div>
      </template>
      <template v-slot:end>
        <ul class="block_list">
          <li class="download_1">
            <Button icon="pi pi-upload" class="button-table mr-2" mode="basic" @click="importdocuments"
                    label="Import" chooseLabel="Import"/>
            <!--            <i class="pi pi-file-excel"></i>-->
          </li>
          <!--          <li class="download_2">-->
          <!--            <Button icon="pi pi pi-cloud-download" class="button-table mr-2" accept="image/*" :maxFileSize="1000000"-->
          <!--                    label="" chooseLabel="Import"/>-->
          <!--            <i class="pi pi-file-excel"></i>-->
          <!--          </li>-->
          <!--          <li class="download_3">-->
          <!--            <Button icon="pi pi pi-cloud-download" class="button-table mr-2" accept="image/*" :maxFileSize="1000000"-->
          <!--                    label="" chooseLabel="Import"/>-->
          <!--            <i class="pi pi-file-excel"></i>-->
          <!--          </li>-->
        </ul>
      </template>
    </Toolbar>
    <div class="col-12 ">
      <div class="card">
        <Toast/>
        <Toolbar class="block_add_btn">
          <template v-slot:start>
            <div class="my-2">
              <Button :label="t('AddRegulatoryText')" icon="pi pi-plus" class="add-text p-button-success mr-2"
                      @click="openNew"/>
            </div>
          </template>
        </Toolbar>
        <div class="grid">
          <div class="col-12 pb-0">
            <h5>{{ t('search') }}</h5>
            <div class="grid formgrid">
              <div class="field-control col">
                <label for="theme">{{ t('theme') }}</label>
                <Dropdown v-model="categoryValueTheme" :options="categoriesTheme" optionLabel="name"
                          :placeholder="t('select')" @change="changeTheme"/>
              </div>
              <div class="field-control col">
                <label for="domaine">{{ t('domain') }}</label>
                <Dropdown v-model="categoryValueDomain" :options="categoriesDomain" optionLabel="name"
                          :placeholder="t('select')" @change="changeDomain"/>
              </div>
              <div class="field-control col">
                <label for="sous_domaine">{{ t('subDomain') }}</label>
                <Dropdown v-model="categoryValueSubDomain" :options="categoriesSubDomain" optionLabel="name"

                          :placeholder="t('select')"/>
              </div>
            </div>
            <div class="grid formgrid pt-3">
              <div class="field-control col-12">
                <label for="text_admin">{{ t('text') }}</label>
                <Textarea :placeholder="t('yourText')" rows="3" cols="30" v-model="textAdmin"/>


                <!--                <Editor v-model="textAdmin" editorStyle="height: 160px"/>-->


              </div>
              <div class="field-control py-4 btn-search">
                <Button type="button" class="mr-2 mb-2 btn-search" :label="t('search')" icon="pi pi-search"
                        :loading="loading[0]" @click="loadDocuments(1)"/>
              </div>
            </div>
          </div>
        </div>
        <div class="section_result_table">
          <h5 class="mt-0">{{ t('result') }}</h5>
          <DataTable :value="documents['hydra:member']" :lazy="true" :totalRecords="documents['hydra:totalItems']"
                     @page="onPage($event)" :paginator="true" class="p-datatable-gridlines table-filter-texte"
                     :rows="30" dataKey="id" :rowHover="true"
                     v-model:filters="filters1" filterDisplay="menu" :loading="loadingDocuments" :filters="filters1"
                     responsiveLayout="scroll"
                     :globalFilterFields="['titleDescription','dated_at','last_updated_at','theme','domain','subDomain']"
                     @filter="filterDataTable($event)"
          >
            <template #empty>
              {{ t('NoDataToDisplay') }}
            </template>
            <template #loading>
              {{ t('LoadingDataPleaseWait') }}
            </template>
            <Column header="">
              <template #body="slotProps">
                <span class="p-column-title"></span>
                <img v-if="slotProps.data.country" :src="'demo/images/flags/pi-'+slotProps.data.country.id+'.png'"
                     :alt="slotProps.data.image" width="15"/>
              </template>
            </Column>
            <Column header="" class="td_flag">
              <template #body="slotProps">
                <span class="p-column-title"></span>
                <img :src="'demo/images/flags/flag-black.png'" :alt="slotProps.data.image" width="15"
                     v-if="slotProps.data?.live_status?.color=='black'"/>
                <img :src="'demo/images/flags/flag-red.png'" :alt="slotProps.data.image" width="15"
                     v-if="slotProps.data?.live_status?.color=='red'"/>
                <img :src="'demo/images/flags/flag-yellow.png'" :alt="slotProps.data.image" width="15"
                     v-if="slotProps.data?.live_status?.color=='yellow'"/>
                <img :src="'demo/images/flags/flag-green.png'" :alt="slotProps.data.image" width="15"
                     v-if="slotProps.data?.live_status?.color=='green'"/>
              </template>
            </Column>
            <Column field="name" :header="t('text')" style="width:10rem" filterField="titleDescription"
                    :showFilterMatchModes="false">
              <template #body="{data}">
                <div class="tooltip"
                     v-tooltip.right="'<p><strong> '+  t('text') +' : </strong>'+data.title+'</p><p><strong>Description : </strong>'+
                      data.description+'</p><p><strong>'+t('fieldApplication') +' : </strong>'+data.application_fields+'</p>'">
                  <p>{{ data.title }}</p>
                  <p v-html="data.description" v-if="data.description != 'null'"></p>
                </div>
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" :placeholder="t('search')"/>
              </template>
            </Column>
            <Column :header="t('dateOfText')" style="min-width:8rem" filterField="dated_at"
                    :showFilterMatchModes="false">
              <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle"
                      class="image-text"> {{ formatDate(data.dated_at) }}</span>
              </template>
              <template #filter="{filterModel}">
                <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"/>
              </template>
            </Column>
            <Column :header="t('updatedAt')" style="min-width:9rem" filterField="last_updated_at"
                    :showFilterMatchModes="false">
              <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle"
                      class="image-text">{{ formatDate(data.last_updated_at) }}</span>
              </template>
              <template #filter="{filterModel}">
                <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"/>
              </template>
            </Column>
            <!--            <Dropdown v-model="categoryValueTheme" :options="categoriesTheme" optionLabel="name"-->
            <!--                      placeholder="Sélectionner" @change="changeTheme"/>-->
            <Column :header="t('theme')" filterField="theme" style="min-width:8rem" :showFilterMatchModes="false">
              <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle" v-if="data.theme"
                      class="image-text">{{ data.theme.name }}</span>
              </template>
              <template #filter="{filterModel}">
                <Dropdown v-model="filterModel.value" :options="categoriesTheme" placeholder=" Any "
                          class="p-column-filter" :showClear="true">
                  <template #value="slotProps">
                    <span :class="'customer-badge status-' + slotProps.value"
                          v-if="slotProps.value">{{ slotProps.value.label }}</span>
                    <span v-else>{{ t('select') }}</span>
                  </template>
                  <template #option="slotProps">
                    <span :class="'customer-badge status-' + slotProps.option.label">{{ slotProps.option.label }}</span>
                  </template>
                </Dropdown>
              </template>
            </Column>
            <Column :header="t('domain')" filterField="domain" :showFilterMatchModes="false"
                    :filterMenuStyle="{'width':'14rem'}" style="min-width:8rem">
              <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle" v-if="data.domain"
                      class="image-text">{{ data.domain.name }}</span>
              </template>
              <template #filter="{filterModel}">
                <Dropdown v-model="filterModel.value" :options="categoriesDomain" placeholder=" Any "
                          class="p-column-filter" :showClear="true">
                  <template #value="slotProps">
                    <span :class="'customer-badge status-' + slotProps.value"
                          v-if="slotProps.value">{{ slotProps.value.label }}</span>
                    <span v-else>{{ t('select') }}</span>
                  </template>
                  <template #option="slotProps">
                    <span :class="'customer-badge status-' + slotProps.option.label">{{ slotProps.option.label }}</span>
                  </template>
                </Dropdown>
              </template>
            </Column>
            <Column :header="t('subDomain')" filterField="subDomain" style="min-width:8rem"
                    :showFilterMatchModes="false">
              <template #body="{data}">
                <span v-if="data.sub_domain">{{ data.sub_domain.name }}</span>
              </template>
              <template #filter="{filterModel}">
                <Dropdown v-model="filterModel.value" :options="categoriesSubDomain" placeholder=" Any "
                          class="p-column-filter" :showClear="true">
                  <template #value="slotProps">
                    <span :class="'customer-badge status-' + slotProps.value"
                          v-if="slotProps.value">{{ slotProps.value.label }}</span>
                    <span v-else>{{ t('select') }}</span>
                  </template>
                  <template #option="slotProps">
                    <span :class="'customer-badge status-' + slotProps.option.label">{{ slotProps.option.label }}</span>
                  </template>
                </Dropdown>
              </template>
            </Column>


            <!--            <Column :header="t('priority')" filterField="balance" dataType="numeric" style="min-width:7rem">-->
            <!--              <template #body="{data}">-->
            <!--                {{ formatCurrency(data.balance) }}-->
            <!--              </template>-->
            <!--            </Column>-->
            <Column :header="t('update')" style="min-width:9rem">
              <template #body="slotProps">
                <Button icon="pi pi-cog" class="btn_edit button-table mr-2" @click="editDocument(slotProps.data)"
                        v-tooltip.top="{value:t('edit'), class: 'editBtn'}"/>
                <Button icon="pi pi-fw pi-comment" class="btn_comment button-table mr-2"
                        @click="editDocument(slotProps.data)"
                        v-tooltip.top="{value:t('onlineAssistance'), class: 'editBtn'}"/>

                <!--                <Button icon="pi pi-link" class="btn_link button-table mr-2" @click="editDocument(slotProps.data)"/>-->
                <a :href="slotProps.data.url" target="_blank">
                  <Button icon="pi pi-link" class="btn_link button-table mr-2"
                          v-tooltip.top="{value:t('link'), class: 'editBtn'}"/>
                </a>

                <Button icon="pi pi-file-pdf" class="btn_file_pdf button-table mr-2"
                        @click="attachFile(slotProps.data.summary_file?.id, slotProps.data.summary_file?.name)"  v-tooltip.top="{value:t('synthesis'), class: 'editBtn'}"
                        :disabled="!slotProps.data.summary_file"/>

                <Button icon="pi pi-file" class="btn_file button-table mr-2"  @click="attachFile(slotProps.data.illustrations_file?.id, slotProps.data.illustrations_file?.name)"
                        v-tooltip.top="{value:'Document', class: 'editBtn'}" :disabled="!slotProps.data.illustrations_file"/>
              </template>
            </Column>
          </DataTable>
        </div>
        <Dialog v-model:visible="addTextDialog" :style="{width: '900px'}" :header="t('textManagemet')"
                :modal="true" class="modal_general p-fluid">
          <TabView class="tabs_texte_admin tabview-custom" v-model:activeIndex="activeIndex"
          >
            <TabPanel>
              <template #header>
                <span>Identification</span>
              </template>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="titre">{{ t('title') }}<span>*</span> :</label>
                  <InputText id="name" v-model.trim="text.title" required="true" autofocus
                             :class="{'p-invalid': submittedDocument && !text.title}"/>
                  <small class="p-invalid" v-if="submittedDocument && !text.title">{{ t('titleRequired') }}</small>
                </div>
                <div class="field-control col">
                  <label for="statut">{{ t('status') }}<span>*</span></label>
                  <Dropdown v-model="statusText" :options="statusDocument" optionLabel="name" required="true" autofocus
                            :class="{'p-invalid': submittedDocument && !statusText}"/>
                  <small class="p-invalid" v-if="submittedDocument && !statusText">{{ t('statusRequired') }}</small>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="statut">{{ t('defaultApplicability') }}</label>
                  <Dropdown v-model="valueDefaultApp" :options="valuesDefaultApp" optionLabel="label"/>
                </div>
                <div class="field-control col">
                  <label for="Priorite">Type<span>*</span> </label>
                  <!--                  <InputText type="text" v-model="text.document_type"-->
                  <!--                             :class="{'p-invalid': submitted && !text.document_type}"/>-->
                  <!--                  <small class="p-invalid" v-if="submitted && !text.document_type">document type is required.</small>-->

                  <Dropdown v-model="typeDocument" :options="typesDocument" optionLabel="Type"
                            :class="{'p-invalid': submittedDocument && !typeDocument}"/>
                  <small class="p-invalid" v-if="submittedDocument && !typeDocument">{{
                      t('documentTypeRequired')
                    }}</small>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="statut">{{ t('subDomain') }}<span>*</span></label>
                  <!--                  <Dropdown v-model="text.sub_domain" :options="valuesSubDomain" optionLabel="name" />-->
                  <TreeSelect v-model="valueSubDomain" :options="tabCategories" selectionMode="single"
                              @node-select="selectNode"
                              placeholder="Select Item" :class="{'p-invalid': submittedDocument && !valueSubDomain}"/>
                  <small class="p-invalid" v-if="submittedDocument && !valueSubDomain">{{
                      t('subDomainRequired')
                    }}</small>
                  <!--                  <Dropdown v-model="selectedGroupedCity" :options="groupedCities"-->
                  <!--                            optionLabel="label" optionGroupLabel="label" optionGroupChildren="items">-->
                  <!--                  </Dropdown>-->
                </div>
                <div class="field-control col">
                  <label for="statut">{{ t('country') }}</label>
                  <Dropdown v-model="text.country" :options="valueCountries" optionLabel="name"/>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="description">Description :</label>
                  <!--                  <Textarea id="description" v-model="text.description" required="true" rows="5" cols="20"/>-->
                  <!--                  editorStyle="height: 160px"-->
                  <Editor v-model="text.description" editorStyle="height: 160px"/>
                </div>

              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="commentaire">{{ t('comment') }} :</label>
                  <Textarea id="commentaire" v-model="text.comment" required="true" rows="5" cols="20"/>
                </div>
                <div class="field-control col">

                  <div class="field-radiobutton pt-4">
                    <Checkbox id="checkOption1" name="option" :binary="true" v-model="text.is_confidential"/>
                    <label for="confidentiel">{{ t('confidential') }}</label>
                  </div>
                </div>
              </div>
              <div class="formgrid grid">

                <div class="field-control col">
                  <label for="app">{{ t('fieldApplication') }} :</label>
                  <Textarea id="app" v-model="text.application_fields" required="true" rows="5" cols="20"/>
                </div>

                <div class="field-control col">
                  <label for="app">{{ t('novalliaAnnotation') }} :</label>
                  <Textarea id="app" v-model="text.annotation_novallia" required="true" rows="5" cols="20"/>
                </div>
              </div>
<!--              <div class="formgrid grid">-->

<!--                <div class="field-control col">-->
<!--                  <div class="field-radiobutton pt-4">-->
<!--                    <Checkbox id="checkOption1" name="option" :binary="true" v-model="text.local_text_rte"/>-->
<!--                    <label for="confidentiel">{{ t('localTextRte') }}</label>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="field-control col">-->
<!--                </div>-->
<!--              </div>-->
            </TabPanel>
            <TabPanel :disabled="!isIdentification">
              <template #header>
                <span>{{ t('keyDates') }}</span>
              </template>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="titre">{{ t('dateOfText') }} :</label>
                  <Calendar :showIcon="true" :showButtonBar="true" v-model="dated_at_text"
                            dateFormat="dd/mm/yy"></Calendar>
                </div>
                <div class="field-control col">
                  <label for="statut">{{ t('dateOfLastModification') }} :</label>
                  <Calendar :showIcon="true" :showButtonBar="true" v-model="last_updated_at_text"
                            dateFormat="dd/mm/yy"></Calendar>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="textupdate">{{ t('textModifier') }} :</label>
                  <!--                  <Textarea id="textupdate" v-model="text.update_reason" required="true" rows="5" cols="20"/>-->

                  <Editor v-model="text.update_reason" editorStyle="height: 160px"/>
                </div>
              </div>
            </TabPanel>

            <TabPanel :disabled="!isDateKey">
              <template #header>
                <span>{{ t('siteAssignment') }}</span>
              </template>
              <div class="grid pb-4">
                <div class="col-6">
                  <Dropdown v-model="valueStatusAffect" :options="valuesStatusAffect" optionLabel="label"/>
                </div>
              </div>
              <PickList v-model="listValuesSite" dataKey="code" @move-to-target="moveToTarget"
                        @move-to-source="moveToSource" :responsive="true" listStyle="height: 250px"
                        class="picklist_site">
                <template #sourceheader>
                  <h5>{{ t('sitesAssociated') }}</h5>
                  <div class="grid-box grid">
                    <div class="col-7" style="border-right: 1px solid">{{ t('reference') }}</div>
                    <div class="col-5">{{ t('applicability') }}</div>
                  </div>
                </template>
                <template #targetheader>
                  <h5>{{ t('SitesNotAssociated') }}</h5>

                  <div class="grid-box grid">

                    <div class="col-7" style="border-right: 1px solid">{{ t('reference') }}

                    </div>
                    <div class="col-5">{{ t('wording') }}</div>

                    <input type="text" id="myInput" @keyup="filterSites()" :placeholder="t('searchByName')"
                           title="Type in a name" class="p-inputtext p-component p-column-filter p-column-filter"
                           style="background-color: white;">
                  </div>
                </template>
                <template #item="slotProps">
                  <div class="list_related_site flex justify-content-between">
                    <div class="col-7"
                         v-tooltip.right="slotProps.item.concatSitesName"
                    >
                      {{ truncateSiteName(slotProps.item.concatSitesName, 30) }}
                    </div>
                    <div class="col-5" v-tooltip.right="slotProps.item.typeLabel">
                      {{ truncateSiteName(slotProps.item.typeLabel, 20) }}
                    </div>
                    <span style="display: none">
                      {{ slotProps.item.concatSitesName }}
                    </span>
                  </div>
                </template>
              </PickList>
            </TabPanel>
            <TabPanel :disabled="!isAffectationSite">
              <template #header>
                <span>Documents</span>
              </template>
              <div class="formgrid grid">
                <div class="field-control col-12">
                  <label for="uploadfiles">Fiche de synthése :</label>
                  <FileUpload mode="basic" ref="fileSummary" label="Import"
                              chooseLabel="Import" class="mr-2 inline-block"
                              v-on:change="onUploadSummaryFile()"/>
                </div>
                <div class="field-control col-12">
                  <label for="uploadfileannex">Fichier Annexes et illustrations :</label>
                  <FileUpload mode="basic" ref="fileIllustration" label="Import"
                              chooseLabel="Import" class="mr-2 inline-block"
                              v-on:change="onUploadIllustrationFile()"/>
                </div>
                <div class="field-control col-12">
                  <label for="titre">URL:</label>
                  <InputText id="name" v-model="urlDoc" required="true" autofocus class="mt-2"/>
                </div>
              </div>
            </TabPanel>
          </TabView>

          <template #footer>
            <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove" @click="hideDialog"/>
            <Button :label="nameButtonSaveDoc" icon="pi pi-check" class="p-button-text next_btn" @click="saveDocument"
                    :disabled="btnDocumentSubmit"/>
          </template>
        </Dialog>
        <Dialog v-model:visible="editDocumentDialog" :style="{width: '900px'}" header="Modifier un document"
                :modal="true" class="modal_general p-fluid">
          <!--modal edit document -->
          <DataTable :value="sectionsText" v-model:expandedRows="expandedRows" dataKey="id" responsiveLayout="scroll"
                     @rowExpand="onRowExpand" class="update_doc">
            <template #header>
              <div>
                <p>{{ dataDocument.title }}</p>
              </div>
            </template>
            <Column :expander="true" headerStyle="width: 3rem"/>
            <Column field="title" class="title_section">
              <template #body="slotProps">
                {{ slotProps.data.title }}
              </template>
            </Column>
            <template #expansion="slotProps">
              <div class="p-3 pt-0">
                <DataTable :value="slotProps.data.compliances" responsiveLayout="scroll" class="sub_table_doc">
                  <Column>
                    <template #body="slotProps">
                      {{ slotProps.data.title }}
                    </template>
                  </Column>
                  <template #empty>
                    No campliance found.
                  </template>
                </DataTable>
              </div>
            </template>
          </DataTable>
        </Dialog>

        <Dialog v-model:visible="importDocumentDialog" :style="{width: '900px'}" :header="t('importDocument')"
                :modal="true"
                class="modal_general p-fluid">

          <div class="formgrid grid">
            <div class="field-control col-12">
              <label for="uploadfiles">{{ t('documentFile') }}:</label>
              <FileUpload mode="basic" accept=".xlsx" ref="file" label="Import"
                          chooseLabel="Import" class="mr-2 inline-block"
                          v-on:change="onUploadDocuments()"/>
            </div>
          </div>
          <template #footer>
            <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                    @click="hideDialogImportSites"/>
            <Button :label="t('import')" icon="pi pi-check" class="p-button-text next_btn"
                    @click="importDocument()"/>
          </template>
        </Dialog>
      </div>
    </div>
  </div>
  <Toast/>
</template>
<script>
// import {FilterMatchMode, FilterOperator} from 'primevue/api';
import {FilterMatchMode} from 'primevue/api';
import {useStore} from "vuex";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
// import {LocaleFr} from "@/store/translation/StoreTranslation";
import router from "@/router";
// import moment from "moment";
import moment from 'moment-timezone'
import {useToast} from "primevue/usetoast";
import Editor from 'primevue/editor';
import {usePrimeVue} from "primevue/config";
import {computed} from "vue";
import {saveAs} from 'file-saver';

export default {
  components: {
    Editor,
  },
  data() {
    return {
      picklistValue: [[
        {name: 'San Francisco', code: 'SF'},
        {name: 'London', code: 'LDN'},
        {name: 'Paris', code: 'PRS'},
        {name: 'Istanbul', code: 'IST'},
        {name: 'Berlin', code: 'BRL'},
        {name: 'Barcelona', code: 'BRC'},
        {name: 'Rome', code: 'RM'},
      ], []],
      filters1: null,
      customer1: null,
      loadingDocuments: false,
      checkboxValue: [],
      calendarValue: null,
      loading: [false, false, false],
      dropdownValuesTheme: [],
      dropdownValueTheme: null,
      valuePriorites: [
        {priorite: 'priorite 1', code: 'NY'},
        {priorite: 'priorite 2', code: 'RM'},
        {priorite: 'priorite 3', code: 'LDN'},
      ],
      valuePriorite: null,
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      product: {},
      selectedProducts: null,
      filters: {},
      submittedDocument: false,
      statuses: [
        {label: 'INSTOCK', value: 'instock'},
        {label: 'LOWSTOCK', value: 'lowstock'},
        {label: 'OUTOFSTOCK', value: 'outofstock'}
      ],
      addTextDialog: false,
      categoriesTheme: [],
      categoryValueTheme: null,
      categoriesDomain: [],
      categoryValueDomain: null,
      categoriesSubDomain: [],
      categoryValueSubDomain: null,
      documents: [],
      textAdmin: '',
      text: {'is_confidential': false},
      sectionsText: null,
      expandedRows: [],
      camplianceText: null,
      editDocumentDialog: false,
      dataDocument: null,
      valueCountries: [],
      valuesSubDomain: [],
      valueDocument: null,
      listValuesSite: [[], []],
      valuesStatusAffect: [
        {id: 0, label: 'Pour information', is_applicable: false},
        {id: 1, label: 'Avec les exigences', is_applicable: true},
      ],
      valueStatusAffect: '',
      tabCategories: [],
      statusDocument: [],
      selectedGroupedCity: null,
      groupedCities: [{
        label: 'Germany', code: 'DE',
        items: [{
          groupedCities: [{
            label: 'Germany1', code: 'DE1',
            items: [
              {label: 'Chicago', value: 'Chicago'},
              {label: 'Los Angeles', value: 'Los Angeles'},
              {label: 'New York', value: 'New York'},
              {label: 'San Francisco', value: 'San Francisco'}
            ]
          }]
        }]
      }],
      valuesDefaultApp: [
        {value: 1, label: 'Applicable'},
        {value: 2, label: 'Pour information'}
      ],
      valueDefaultApp: '',
      valueSubDomain: '',
      representatives: [
        {name: "Amy Elsner", image: 'amyelsner.png'},
        {name: "Anna Fali", image: 'annafali.png'},
        {name: "Asiya Javayant", image: 'asiyajavayant.png'},
        {name: "Bernardo Dominic", image: 'bernardodominic.png'},
        {name: "Elwin Sharvill", image: 'elwinsharvill.png'},
        {name: "Ioni Bowcher", image: 'ionibowcher.png'},
        {name: "Ivan Magalhaes", image: 'ivanmagalhaes.png'},
        {name: "Onyama Limba", image: 'onyamalimba.png'},
        {name: "Stephen Shaw", image: 'stephenshaw.png'},
        {name: "XuXue Feng", image: 'xuxuefeng.png'}
      ],
      statusess: [
        'unqualified', 'qualified', 'new', 'negotiation', 'renewal', 'proposal'
      ],
      nbPageDocument: 1,
      dated_at: '',
      last_updated_at: '',
      dated_at_text: '',
      last_updated_at_text: '',
      activeIndex: 0,
      isIdentification: false,
      isDateKey: false,
      isAffectationSite: false,
      isDocuments: false,
      nameButtonSaveDoc: this.t('next'),
      statusText: '',
      last_updated_at_format: '',
      dated_at_format: '',
      typesDocument: [
        {
          "Type": "Directive",
          "TypeId": "72"
        },
        {
          "Type": "Décision",
          "TypeId": "70"
        },
        {
          "Type": "Règlement",
          "TypeId": "36"
        },
        {
          "Type": "Avis",
          "TypeId": "66"
        },
        {
          "Type": "Code de l'environnement",
          "TypeId": "86"
        },
        {
          "Type": "Arrêté",
          "TypeId": "9"
        },
        {
          "Type": "Circulaire",
          "TypeId": "97"
        },
        {
          "Type": "Décret",
          "TypeId": "71"
        },
        {
          "Type": "Code de la santé publique",
          "TypeId": "109"
        },
        {
          "Type": "Autre exigence",
          "TypeId": "107"
        },
        {
          "Type": "Plan",
          "TypeId": "255"
        },
        {
          "Type": "Courrier",
          "TypeId": "69"
        },
        {
          "Type": "Code de la construction et de l'habitation ",
          "TypeId": "141"
        },
        {
          "Type": "SDAGE",
          "TypeId": "121"
        },
        {
          "Type": "Loi",
          "TypeId": "73"
        },
        {
          "Type": "Code de l'urbanisme",
          "TypeId": "110"
        },
        {
          "Type": "Code général des collectivités territoriales",
          "TypeId": "166"
        },
        {
          "Type": "Note",
          "TypeId": "99"
        },
        {
          "Type": "RSD",
          "TypeId": "120"
        },
        {
          "Type": "Plan d'Occupation des Sols",
          "TypeId": "115"
        },
        {
          "Type": "Plan Local d'Urbanisme",
          "TypeId": "116"
        },
        {
          "Type": "Code du commerce",
          "TypeId": "68"
        },
        {
          "Type": "Code des douanes",
          "TypeId": "112"
        },
        {
          "Type": "Code rural et de la pêche maritime",
          "TypeId": "113"
        },
        {
          "Type": "Arrêté-type",
          "TypeId": "95"
        },
        {
          "Type": "Arrêté de prescriptions",
          "TypeId": "278"
        },
        {
          "Type": "Arrêté de prescription",
          "TypeId": "91"
        },
        {
          "Type": "Arrêté sectoriel",
          "TypeId": "94"
        },
        {
          "Type": "Arrêté d'enregistrement",
          "TypeId": "92"
        },
        {
          "Type": "Code du travail",
          "TypeId": "98"
        },
        {
          "Type": "PPRI",
          "TypeId": "117"
        },
        {
          "Type": "Arrêté préfectoral",
          "TypeId": "93"
        },
        {
          "Type": "Instruction",
          "TypeId": "130"
        },
        {
          "Type": "Convention",
          "TypeId": "114"
        },
        {
          "Type": "Code Forestier",
          "TypeId": "123"
        },
        {
          "Type": "Code des coll. Terr.",
          "TypeId": "111"
        },
        {
          "Type": "Code du patrimoine",
          "TypeId": "122"
        },
        {
          "Type": "Rapport",
          "TypeId": "119"
        },
        {
          "Type": "Liste",
          "TypeId": "168"
        },
        {
          "Type": "Communication",
          "TypeId": "128"
        },
        {
          "Type": "Recommandation CNAMTS",
          "TypeId": "131"
        },
        {
          "Type": "Code pénal",
          "TypeId": "127"
        },
        {
          "Type": "Code de la sécurité sociale",
          "TypeId": "126"
        },
        {
          "Type": "Code civil",
          "TypeId": "125"
        },
        {
          "Type": "Accord",
          "TypeId": "124"
        },
        {
          "Type": "Procès-verbal",
          "TypeId": "118"
        },
        {
          "Type": "Rubriques ICPE",
          "TypeId": "170"
        },
        {
          "Type": "DDRM",
          "TypeId": "171"
        },
        {
          "Type": "PPRT",
          "TypeId": "172"
        },
        {
          "Type": "Code de l'énergie",
          "TypeId": "142"
        },
        {
          "Type": "Orientations",
          "TypeId": "201"
        },
        {
          "Type": "Code de l'aviation civile",
          "TypeId": "202"
        },
        {
          "Type": "Code des transports",
          "TypeId": "67"
        },
        {
          "Type": "Code général des impôts",
          "TypeId": "203"
        },
        {
          "Type": "Résolution",
          "TypeId": "205"
        },
        {
          "Type": "Code des collectivités territoriales",
          "TypeId": "204"
        },
        {
          "Type": "Code de la route",
          "TypeId": "37"
        },
        {
          "Type": "Code rural",
          "TypeId": "140"
        },
        {
          "Type": "Grille d'audit",
          "TypeId": "208"
        },
        {
          "Type": "Règlement ERP",
          "TypeId": "210"
        },
        {
          "Type": "Instruction technique",
          "TypeId": "209"
        },
        {
          "Type": "Ordonnance Fédérale",
          "TypeId": "213"
        },
        {
          "Type": "Loi Fédérale",
          "TypeId": "212"
        },
        {
          "Type": "Loi Cantonale",
          "TypeId": "211"
        },
        {
          "Type": "Règlement cantonal",
          "TypeId": "227"
        },
        {
          "Type": "Recommandation",
          "TypeId": "226"
        },
        {
          "Type": "Autres exigences",
          "TypeId": "215"
        },
        {
          "Type": "Constitution Fédérale",
          "TypeId": "216"
        },
        {
          "Type": "Arrêté de mise en demeure",
          "TypeId": "217"
        },
        {
          "Type": "Code du travail applicable à Mayotte",
          "TypeId": "218"
        },
        {
          "Type": "Contrat",
          "TypeId": "220"
        },
        {
          "Type": "Directive cantonale",
          "TypeId": "222"
        },
        {
          "Type": "Code de la consommation",
          "TypeId": "221"
        },
        {
          "Type": "Ordonnance",
          "TypeId": "225"
        },
        {
          "Type": "Projet",
          "TypeId": "224"
        },
        {
          "Type": "Charte Lacroix",
          "TypeId": "229"
        },
        {
          "Type": "Code de la défense",
          "TypeId": "239"
        },
        {
          "Type": "SAGE",
          "TypeId": "243"
        },
        {
          "Type": "Délibération",
          "TypeId": "129"
        },
        {
          "Type": "Information",
          "TypeId": "245"
        },
        {
          "Type": "Norme",
          "TypeId": "254"
        },
        {
          "Type": "PPRN",
          "TypeId": "257"
        },
        {
          "Type": "PPRif",
          "TypeId": "256"
        },
        {
          "Type": "Shéma",
          "TypeId": "258"
        },
        {
          "Type": "Note d'information",
          "TypeId": "259"
        },
        {
          "Type": "Lois",
          "TypeId": "260"
        },
        {
          "Type": "RGPT",
          "TypeId": "263"
        },
        {
          "Type": "Code du bien-être au travail",
          "TypeId": "262"
        },
        {
          "Type": "Arrêté royal",
          "TypeId": "264"
        },
        {
          "Type": "Guide",
          "TypeId": "267"
        },
        {
          "Type": "UK Statutory Instruments",
          "TypeId": "273"
        },
        {
          "Type": "UK Public General Acts",
          "TypeId": "272"
        },
        {
          "Type": "UK Statutory Instruments",
          "TypeId": "271"
        },
        {
          "Type": "Protocole",
          "TypeId": "275"
        },
        {
          "Type": "PPR",
          "TypeId": "276"
        },
        {
          "Type": "Code Minier",
          "TypeId": "277"
        },
        {
          "Type": "Code des eaux",
          "TypeId": "279"
        },
        {
          "Type": "CSPRIEPB",
          "TypeId": "289"
        },
        {
          "Type": "Code de l'aménagement du territoire et de l'urbanisme",
          "TypeId": "282"
        },
        {
          "Type": "Code des hydrocarbures",
          "TypeId": "283"
        },
        {
          "Type": "Code des ports maritimes",
          "TypeId": "284"
        },
        {
          "Type": "Code d'incitation aux investissements",
          "TypeId": "286"
        },
        {
          "Type": "Code des marchés publics",
          "TypeId": "288"
        },
        {
          "Type": "Note technique",
          "TypeId": "290"
        },
        {
          "Type": "Dahir",
          "TypeId": "292"
        },
        {
          "Type": "Recommandations",
          "TypeId": "214"
        },
        {
          "Type": "Arrêté viziriel",
          "TypeId": "291"
        },
        {
          "Type": "Projet",
          "TypeId": "280"
        },
        {
          "Type": "Code de la contruction et de l'habitation",
          "TypeId": "265"
        },
        {
          "Type": "Code de l'eau",
          "TypeId": "268"
        },
        {
          "Type": "Loi organique",
          "TypeId": "287"
        },
        {
          "Type": "Code de la sécurité intérieure",
          "TypeId": "314"
        },
        {
          "Type": "Code de la voirie routière",
          "TypeId": "313"
        }
      ],
      typeDocument: '',
      categoriesSubDmainAdd: [],
      idThemeR: '',
      idDomainR: '',
      idSubDomainR: '',
      textR: '',
      dated_atR: '',
      last_updated_atR: '',
      importDocumentDialog: false,
      fileImportDocuments: '',
      btnDocumentSubmit: false,
      summaryFile: null,
      illustrationFile: null,
      urlDoc: '',
      newIdDoc: '',
    }
  },

  setup() {
    const primevue = usePrimeVue();
    const store = useStore();
    const toast = useToast();
    const tools = computed(() => {
      return store.getters.toolsCurrent
    });
    const user = computed(() => {
      return store.getters.currentUser
    });
    let user_id = user.value?.id;
    return {store, toast, primevue, t: primevue.config.StoreTranslation.t, tools, user_id};
  },
  async created() {
    this.initFilters1();
  },
  async mounted() {
    /* Text **/
    await this.getCategoriesByTheme();
    if (this.tools.idTheme || this.tools.idDomain || this.tools.idSubDomain || this.tools.text || this.tools.dated_at || this.tools.last_updated_at) {
      this.idThemeR = this.tools.idTheme
      this.idDomainR = this.tools.idDomain
      this.idSubDomainR = this.tools.idSubDomain
      this.textR = this.tools.text
      this.dated_atR = this.tools.dated_at
      this.last_updated_atR = this.tools.last_updated_at
      if (this.textAdmin == '') {
        if (this.textR != '') {
          this.textAdmin = this.textR
        }
      }
      await this.loadDocuments()
    } else if (this.tools.search) {
      await this.loadDocuments()
    }
  },
  watch: {
    tools() {
      this.idThemeR = this.tools.idTheme
      this.idDomainR = this.tools.idDomain
      this.idSubDomainR = this.tools.idSubDomain
      this.textR = this.tools.text
    }
  },
  methods: {
    initFilters1() {
      this.filters1 = {
        'titleDescription': {value: null, matchMode: FilterMatchMode.EQUALS},
        'dated_at': {value: null, matchMode: FilterMatchMode.EQUALS},
        'last_updated_at': {value: null, matchMode: FilterMatchMode.EQUALS},
        'theme': {value: null, matchMode: FilterMatchMode.EQUALS},
        'domain': {value: null, matchMode: FilterMatchMode.EQUALS},
        'subDomain': {value: null, matchMode: FilterMatchMode.EQUALS},
      }
    },
    clearFilter1() {
      this.initFilters1();
    },
    onUpload() {
      this.$toast.add({severity: 'success', detail: 'File Uploaded', life: 3000});
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
      return;
    },
    /*** text **/
    openNew() {
      this.text = {'is_confidential': false};
      this.submittedDocument = false;
      this.addTextDialog = true;
      this.getCountries();
      this.getSites();
      this.getStatusDocument();
    },
    hideDialog() {
      this.productDialog = false;
      this.submittedDocument = false;
      this.addTextDialog = false;
    },
    getCategoriesByTheme() {
      let tabCategoriesTheme = []
      let tabCategoriesDomain = []
      let tabCategoriesSubDomain = []
      return this.store.dispatch(Actions.GET_CATEGORIES_TREE, {'customHeader': false,}).then(data => {


        var formatSelectTree = (obj, indexCh = null, parent) => {


          obj.forEach((element, index) => {
            let indexF = '0';
            if (null === indexCh) {
              indexF = index + '';
            } else {
              indexF = indexCh + '-' + index
            }

            if (Object.prototype.hasOwnProperty.call(element, 'categories')) {
              formatSelectTree(element.categories, indexF, element)

              if (element.type == 'THEME') {
                tabCategoriesTheme.push(element)
              } else if (element.type == 'DOMAIN') {
                tabCategoriesDomain.push(element)
              }
              // else {
              //   tabCategoriesSubDomain.push(element)
              // }
            } else {
              if (element.type == 'SUB_DOMAIN') {
                tabCategoriesSubDomain.push(element)
              }
            }

            Object.assign(element, {"children": element.categories})
            //  Object.assign(element, {"label":element.name})
            Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})
            Object.assign(element, {"label": element.name})

            Object.assign(element, {"key": element.id})
            if (element.type == 'THEME') {
              Object.assign(obj[index], {
                "data": {
                  "id": element.id,
                  "name": element.name,
                  "type": element.type,
                  "code": element.code,

                }
              })
            } else if (element.type == 'DOMAIN') {
              Object.assign(obj[index], {
                "data": {
                  "id": element.id,
                  "name": element.name,
                  "type": element.type,
                  "code": element.code,
                  "id_theme": parent.id,

                }
              })
            } else {
              Object.assign(obj[index], {
                "data": {
                  "id": element.id,
                  "name": element.name,
                  "type": element.type,
                  "code": element.code,
                  "id_theme": parent.parent_id,
                  "id_domain": parent.id,

                }
              })
            }
            // parent= obj[index]

          });
          return obj;
        };
        this.tabCategories = formatSelectTree(data)


        // data.forEach(function (element) {
        //   if (element.type === 'THEME') {
        //     tabCategoriesTheme.push(element)
        //   }
        //   if (element.type === 'DOMAIN') {
        //     tabCategoriesDomain.push(element)
        //   }
        //   if (element.type === 'SUB_DOMAIN') {
        //     tabCategoriesSubDomain.push(element)
        //   }
        // });
        this.categoriesTheme = tabCategoriesTheme;
        this.categoriesDomain = tabCategoriesDomain;
        this.categoriesSubDomain = tabCategoriesSubDomain;
        this.categoriesSubDmainAdd = tabCategoriesSubDomain;
        this.valuesSubDomain = tabCategoriesSubDomain;
        // this.dropdownValuesTheme = tabCategoriesTheme
      })
    },
    changeTheme() {
      let tabCategoriesDomain = []
      let tabCategoriesSubDomain = []
      let objTheme = this.categoriesTheme.find(o => o.id == this.categoryValueTheme.id);
      objTheme.categories.forEach(function (element) {
        if (element.type === 'DOMAIN') {
          tabCategoriesDomain.push(element)
        }
        if (element.type === 'SUB_DOMAIN') {
          tabCategoriesSubDomain.push(element)
        }
      })
      this.categoriesDomain = tabCategoriesDomain;
      this.categoriesSubDomain = tabCategoriesSubDomain;
    },
    changeDomain() {
      let tabCategoriesSubDomain = []
      if (this.categoryValueDomain) {
        let objDomain = this.categoriesDomain.find(o => o.id == this.categoryValueDomain.id);
        objDomain.categories.forEach(function (element) {
          if (element.type === 'SUB_DOMAIN') {
            tabCategoriesSubDomain.push(element)
          }
        })
        this.categoryValueSubDomain = 2
        this.categoriesSubDomain = tabCategoriesSubDomain;
      }
    },
    loadDocuments(page = 1) {
      this.loadingDocuments = true
      this.documents = []
      let idTheme = this.idThemeR
      let idDomain = this.idDomainR
      let idSubDomain = this.idSubDomainR
      if (this.categoryValueTheme) {
        idTheme = this.categoryValueTheme.id
      } else {
        let objTheme = this.categoriesTheme.find(o => o.id == idTheme);
        this.categoryValueTheme = objTheme
      }
      if (this.categoryValueDomain) {

        idDomain = this.categoryValueDomain.id
      } else {
        let objDomain = this.categoriesDomain.find(o => o.id == idDomain);
        this.categoryValueDomain = objDomain
      }
      if (this.categoryValueSubDomain) {
        idSubDomain = this.categoryValueSubDomain.id
      } else {
        let objSubDomain = this.categoriesSubDomain.find(o => o.id == idSubDomain);
        this.categoryValueSubDomain = objSubDomain
      }
      if (this.dated_at_format == '') {
        if (this.dated_atR != '') {
          this.dated_at_format = this.dated_atR
        }
      }
      if (this.last_updated_at_format == '') {
        if (this.last_updated_atR != '') {
          this.last_updated_at_format = this.last_updated_atR
        }
      }
      return this.store.dispatch(Actions.GET_DOCUMENTS, {
        'theme': idTheme,
        'domain': idDomain,
        'sub_domain': idSubDomain,
        'text': this.textAdmin,
        'dated_at': this.dated_at_format,
        'last_updated_at': this.last_updated_at_format,
        'customHeader': true,
        limit: 30,
        page: page,
      }).then(data => {
        this.store.commit(Mutations.SET_ID_THEME,
            {
              idTheme: idTheme,
              idDomain: idDomain,
              idSubDomain: idSubDomain,
              text: this.textAdmin,
              dated_at: this.dated_at_format,
              last_updated_at: this.last_updated_at_format,
              search: true
            }
        );
        this.documents = data
        this.loadingDocuments = false
      })
    },
    getSections(data) {
      this.store.dispatch(Actions.GET_SECTIONS, {'document': data.id, 'customHeader': true}).then(data => {
        this.sectionsText = data
      })
    },
    onRowExpand(event) {
      let id_section = event.data.id;
      const sectionObject = this.sectionsText.find(element => element.id == id_section)
      if (!sectionObject.compliances) {
        this.store.dispatch(Actions.GET_COMPLIANCES, {
          'document': this.valueDocument.id,
          'section': id_section,
          'customHeader': true,
        }).then(data => {
          this.camplianceText = data
          Object.assign(sectionObject, {"compliances": data})
        })
      }
    },
    editDocument(data) {
      router.push({name: 'edit-text-general', params: {id: data.id}})
      this.dataDocument = data
      this.getSections(data);
      this.valueDocument = data
    },
    formatDate(value) {
      if (value) {
        return new Date(value).toLocaleDateString('fr-FR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      } else {
        return '';
      }
    },
    saveDocument() {
      this.submittedDocument = true;
      this.text.document_type = this.typeDocument.TypeId
      if (this.text.title && this.statusText && this.text.document_type && this.valueSubDomain) {
        if (this.activeIndex < 3 && this.activeIndex == 0) {
          this.isIdentification = true
          this.activeIndex = 1
        } else if (this.activeIndex < 3 && this.activeIndex == 1 && this.isIdentification) {
          this.isDateKey = true
          this.activeIndex = 2
        } else if (this.activeIndex < 3 && this.activeIndex == 2 && this.isDateKey) {
          this.isAffectationSite = true
          this.activeIndex = 3
          this.nameButtonSaveDoc = this.t('save')
        }
        else if (this.activeIndex < 4 && this.activeIndex == 3 && this.isAffectationSite) {
          this.isDocuments = true
          this.activeIndex = 4
        }
        if (this.isDocuments) {
          let affectations_site = [];
          if (this.valueSubDomain) {
            this.text.sub_domain = '/api/categories/' + Object.keys(this.valueSubDomain)
            let objectSubDomain = this.categoriesSubDmainAdd.find(item => item.id == Object.keys(this.valueSubDomain))
            this.text.domain = '/api/categories/' + objectSubDomain.data.id_domain
            this.text.theme = '/api/categories/' + objectSubDomain.data.id_theme
          }
          if (this.text.country) {
            this.text.country = '/api/countries/' + this.text.country.id
          }
          if (moment(this.dated_at_text).isValid()) {
            this.text.dated_at = moment(this.dated_at_text).format()
          }
          if (moment(this.last_updated_at_text).isValid()) {
            this.text.last_updated_at = moment(this.last_updated_at_text).format()
          }
          if (this.statusText) {
            this.text.status = this.statusText.id
          }
          if (this.valueDefaultApp.value == 1) {
            this.text.is_applicable = true
          } else {
            this.text.is_applicable = false
          }
          this.listValuesSite[0].forEach(element => {
            let idSite = 'api/sites/' + element.id
            affectations_site.push({
              'site': idSite,
              'is_applicable': element.valueLabel,
              created_by: 'api/users/' + this.user_id
            })
          })
          this.text.affectations = affectations_site
          this.text.url = this.urlDoc
          this.btnDocumentSubmit = true
          this.store.dispatch(Actions.ADD_DOCUMENT, this.text).then((data) => {
            this.toast.add({
              severity: 'success',
              detail: this.t('documentHasSuccessfullyAdded'),
              life: 3000
            });
            this.addTextDialog = false
            this.btnDocumentSubmit = false
            this.newIdDoc = data.id
            if (this.summaryFile) {
              this.store.dispatch(Actions.UPLOAD_FILE, {
                type: 'document',
                fileName: this.summaryFile,
                idDoc: this.newIdDoc,
                uploadedBy: this.user_id,
                typeDocument: 'summaryFile'
              }).then(() => {
                this.toast.add({
                  severity: 'success',
                  detail: this.t('fileSuccessfullyUploaded'),
                  life: 3000
                });
              })
            }
            if(this.illustrationFile){
              this.store.dispatch(Actions.UPLOAD_FILE, {
                type: 'document',
                fileName: this.illustrationFile,
                idDoc: this.newIdDoc,
                uploadedBy: this.user_id,
                typeDocument: 'illustrationFile'
              }).then(() => {
                this.toast.add({
                  severity: 'success',
                  detail: this.t('fileSuccessfullyUploaded'),
                  life: 3000
                });
              })
            }
          })
        }
      }
    },
    getCountries() {
      this.store.dispatch(Actions.GET_COUNTRIES, {'customHeader': false,}).then(data => {
        this.valueCountries = data;
      })
    },
    getSites() {
      this.treeSelectNodes = []
      this.store.dispatch(Actions.GET_CUSTOMERS_SITE_TREE, {'customHeader': false}).then(data => {
        var formatSelectTree = (obj, indexCh = null, parent) => {
          obj?.forEach((element, index) => {
            let indexF = '0';
            if (null === indexCh) {
              indexF = index + '';
            } else {
              indexF = indexCh + '-' + index
            }
            if (Object.prototype.hasOwnProperty.call(element, 'childrens')) {
              formatSelectTree(element.childrens, indexF, element)
            }
            Object.assign(element, {"children": element.childrens})
            if (Object.prototype.hasOwnProperty.call(element, 'site_name')) {
              Object.assign(element, {"label": element.site_name})
              element.id = element.site_id
            } else {
              Object.assign(element, {"label": element.name})
            }
            Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})
            Object.assign(element, {"key": element.id})
            if (element.entity_type == 'CUSTOMER') {
              Object.assign(obj[index], {
                "data": {
                  "id": element.id,
                  "name": element.name,
                  "type": element.type,
                  "code": element.code,
                  "customer_name": element.name,
                }
              })
            } else if (element.entity_type == 'entity') {
              Object.assign(obj[index], {
                "data": {
                  "id": element.id,
                  "name": element.name,
                  "type": element.type,
                  "code": element.code,
                  "customer_name": parent.name,
                }
              })
            } else {
              if (parent.site_name) {
                Object.assign(obj[index], {
                  "data": {
                    "id": element.id,
                    "name": element.name,
                    "type": element.type,
                    "code": element.code,
                    "typeLabel": element.site_name,
                    "customer_name": parent.name,
                    "entity_name": parent.site_name,
                    "site_name": element.site_name,
                    "concatSitesName": parent.name + ' - ' + parent.site_name + ' - ' + element.code
                  }
                })
              } else {
                Object.assign(obj[index], {
                  "data": {
                    "id": element.id,
                    "name": element.name,
                    "type": element.type,
                    "code": element.code,
                    "typeLabel": element.site_name,
                    "customer_name": parent.name,
                    "entity_name": parent.site_name,
                    "site_name": element.site_name,
                    "concatSitesName": parent.name + ' - ' + element.code
                  }
                })
              }
              this.treeSelectNodes.push(obj[index].data)
            }

          });
          return obj;
        };
        formatSelectTree(data);
        this.listValuesSite[1] = this.treeSelectNodes
      })
    },

    getStatusDocument() {
      this.store.dispatch(Actions.GET_STATUS_DOCUMENT).then(data => {
        this.statusDocument = data
        this.statusDocument = this.statusDocument.filter(item => item.id !== 54)
      })
    },
    changeSelect(e) {
      alert(e)
    },

    onPage(event) {
      this.nbPageDocument = event.page + 1
      this.loadDocuments(this.nbPageDocument);
    },
    moveToSource(move) {
      move.items[0].typeLabel = this.valueStatusAffect.label;
      move.items[0].valueLabel = this.valueStatusAffect.is_applicable;

    },
    filterDataTable(event) {

      // if(event.filters.titleDescription.value){
      this.textAdmin = event.filters.titleDescription.value
      // }

      if (event.filters.dated_at.value) {
        this.dated_at_format = moment(event.filters.dated_at.value).format()
      }
      if (event.filters.last_updated_at.value) {
        this.last_updated_at_format = moment(event.filters.last_updated_at.value).format()
      }
      this.categoryValueTheme = event.filters.theme.value

      if (event.filters.theme.value) {
        this.changeTheme()
      } else if (!event.filters.domain.value) {
        this.getCategoriesByTheme()
      }

      if (this.categoriesDomain.length > 0) {
        if (event.filters.domain.value != this.categoryValueDomain) {
          this.categoryValueDomain = event.filters.domain.value
          this.changeDomain()
          if ((this.categoryValueSubDomain == 2 && !event.filters.subDomain.value) || (this.categoryValueSubDomain == 2 && event.filters.subDomain.value)) {
            event.filters.subDomain.value = null
          }
        }

        this.categoryValueSubDomain = event.filters.subDomain.value
      } else {
        event.filters.domain.value = null
        event.filters.subDomain.value = null
        this.categoryValueDomain = ''
        this.categoryValueSubDomain = null

      }
      this.loadDocuments(this.nbPageDocument)

    },
    selectNode(selected) {
      if (selected.children?.length) {
        setTimeout(() => {
          this.valueSubDomain = {[selected.id]: false}
        }, 100);
      }
    },
    truncateSiteName(source, size) {
      if (source) {

        return source.length > size ? source.slice(0, size - 1) + " …" : source;

      }
    },

    filterSites() {
      var input, filter, ul, li, a, i, txtValue;
      input = document.getElementById("myInput");
      filter = input.value.toUpperCase();
      ul = document.getElementsByClassName("p-picklist-list p-picklist-target");
      li = ul[0].getElementsByTagName("li");
      // li = ul[0];
      for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("div")[0];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
        }
      }
    },

    importdocuments() {
      this.importDocumentDialog = true
    },
    importDocument() {
      this.store.dispatch(Actions.IMPORT_FILE, {
        file: this.fileImportDocuments,
        model: 'importDocumentText',
      }).then(() => {
        this.toast.add({
          severity: 'success',
          detail: this.t('importFileWasSuccessfullySent'),
          life: 8000
        });
        this.importDocumentDialog = false
      })
    },
    hideDialogImportSites() {
      this.importDocumentDialog = false

    },
    onUploadDocuments() {
      this.fileImportDocuments = this.$refs.file.files[0]
    },
    onUploadSummaryFile() {
      this.summaryFile = this.$refs.fileSummary.files[0]
    },
    onUploadIllustrationFile() {
      this.illustrationFile = this.$refs.fileIllustration.files[0]
    },
    attachFile(idMedia, nameFile) {
      this.store.dispatch(Actions.ATTACH_FILES, {idMedia: idMedia, 'customHeader': true}).then((data, filename) => {
        const url = window.URL.createObjectURL(data, filename);
        saveAs(url, nameFile)
      })
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../../assets/demo/styles/badges';
</style>
